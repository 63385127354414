import { HeaderFactory } from './header/header';
import { AuthStateHandler } from './AuthStateHandler/AuthStateHandler';
import { HeaderType } from './header/header.model';
import { StockBar } from './header/stock-bar';

// Initialize stock bar
window.addEventListener("millistream-ready", () => {
  StockBar.initAnimation();
});

window.FS = {
    esiHeader: HeaderFactory.get(HeaderType.NORMAL),
    esiFeature: HeaderFactory.get(HeaderType.FEATURE),
    authStateHandler: new AuthStateHandler()
};
