import { Header } from "./base";
import { Configuration, HeaderHostApp } from "./header.model";

export class NormalHeaderFactory extends Header {

    init(config: Configuration = { updateStickyTresholdOnScroll: false, permanentlyStickyHeader: false }) {
        super.init(config)

        if (this.config.app === HeaderHostApp.JSP) {
            this.headerElem.classList.add('m--jsp');
        } else if (this.config.app === HeaderHostApp.NG) {
            this.headerElem.classList.add('m--ng');
        }

        if (!this.config.permanentlyStickyHeader) {
            this.stickyTreshold = this.getStickyTreshold();
        } else {
            this.setHeaderSticky();
        }

        this.setupScroll();
    }

    hideMenu(): void {
        super.hideMenu()

        this.updateHeaderStickyState(this.stickyTreshold);
    }

    showMenu(): void {
        super.showMenu()

        if (!this.isSticky) {
            const stickyClassList = ['m--sticky'];

            if (window.innerHeight > this.headerElem.offsetHeight + this.megaMenuScrolledElem.offsetHeight) {
                stickyClassList.push('m--sticky-high');
            }

            this.setHeaderSticky(stickyClassList);
        }
    }

    private setupScroll() {
        if (!this.config.permanentlyStickyHeader) {
            window.addEventListener("scroll",
                () => {
                    if (this.config.updateStickyTresholdOnScroll) {
                        this.stickyTreshold = this.getStickyTreshold();
                    }

                    if (!this.pauseScrollHandler) {
                        this.updateHeaderStickyState(this.stickyTreshold);
                    }
                }
            )
            window.addEventListener("resize", () => {
                this.stickyTreshold = this.getStickyTreshold();
            })
        }
    }

    private updateHeaderStickyState(stickyTreshold: number) {
        if (stickyTreshold <= scrollY) {
            this.setHeaderSticky();
        } else {
            this.unsetHeaderSticky();
        }
    }

    setHeaderSticky(stickyClassList = ['m--sticky']) {
        if (!this.isSticky) {
            window.requestAnimationFrame(() => {
                this.setHeaderPlaceholder(this.headerElem.offsetHeight);
                this.headerElem.classList.add(...stickyClassList);
                document.body.classList.add('m--showing-sticky-header');

                this.isSticky = true;
            });
        }
    }

    unsetHeaderSticky() {
        if (this.isSticky) {
            window.requestAnimationFrame(() => {
                this.isSticky = false;
                this.setHeaderPlaceholder(0);
                this.headerElem.classList.remove("m--sticky", "m--sticky-high");
                document.body.classList.remove('m--showing-sticky-header');
            })
        }
    }

    setHeaderPlaceholder(height: number) {
        if (!this.config.permanentlyStickyHeader) {
            this.headerElem.style.minHeight = height + 'px';
        }
    }

    /**
     * Update sticky treshold and checks whether header should become sticky/not sticky.
     * For use in host applications.
     */
    onAdLoaded() {
        if (!this.config.updateStickyTresholdOnScroll) {
            this.stickyTreshold = this.getStickyTreshold();
            this.updateHeaderStickyState(this.stickyTreshold);
        }
    }
}
