export interface Configuration{
  updateStickyTresholdOnScroll?: boolean,
  app?: 'jsp' | 'ng',
  permanentlyStickyHeader?: boolean
}

export enum HeaderHostApp {
  JSP= 'jsp',
  NG = 'ng'
}

export enum HeaderType {
    NORMAL,
    FEATURE
}
