


export class StockBar {
    static initAnimation() {
        const stockBar: HTMLElement = document.getElementById("finans-stock-bar") as HTMLElement;
        const animatedEl: HTMLElement = stockBar.getElementsByClassName('stock-bar__animation')[0] as HTMLElement;
        const viewportEl: HTMLElement = stockBar.getElementsByClassName('stock-bar__wrapper')[0] as HTMLElement;
        const infPlaceholder: HTMLElement = stockBar.getElementsByClassName('stock-bar__collection2')[0] as HTMLElement
        const articleElements: HTMLCollection = stockBar.getElementsByClassName('stock-bar__collection1')[0].children;
        const stylesEl: HTMLElement= this.prepareStylesElement('tickerAnimationStylesId');
        const animatedElWidth: number = animatedEl.getBoundingClientRect().width;

        this.setAnimationStyles(animatedElWidth, viewportEl.getBoundingClientRect().width, stylesEl);
        window.addEventListener("resize", this.throttle(() => {
            this.setAnimationStyles(animatedElWidth, viewportEl.getBoundingClientRect().width, stylesEl)
        }, 100))

        for(let i = 0; i < articleElements.length; i++) {
            infPlaceholder.appendChild(articleElements[i].cloneNode(true));
        }
    }

    static prepareStylesElement(id: string) {
        const style = document.createElement('style');
        style.setAttribute("id", id);
        document.head.appendChild(style);

        return document.getElementById(id)!;
    }

    static setAnimationStyles(animatedElWidth: number, viewportElWidth: number, stylesEl: HTMLElement) {
        const SPEED = 90; // pixels/second
        const DISTANCE = animatedElWidth + viewportElWidth;
        const STARTING_POINT = .67; // Value between 0 and 1 to decide how far in the viewport the ticker should start

        stylesEl.innerHTML = `@keyframes ticker {
          0% {
            left: 50%;
            visibility: visible;
          }
          100% {
            left: 0%;
          }
        }
        .stock-bar__animation{
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-name: ticker;
            animation-duration: ${DISTANCE / SPEED}s;
            animation-delay: -${(viewportElWidth * STARTING_POINT) / SPEED}s;
        }
        .stock-bar__animation:hover{
                animation-play-state: paused;
        }`;
    }

    static throttle(callback: any, limit: number) {
        let tick = false;
        return function () {
            if (!tick) {
                callback.call();
                tick = true;
                setTimeout(function () {
                    tick = false;
                }, limit);
            }
        }
    }
}
