import { AuthState } from './AuthStateHandler.model';

type UrlOption = string | null; // link disabled iff null

type AuthStateRendering = {
  showLoginButton: boolean;
  showLogoutButton: boolean;
  showBuyButton: boolean;
  savedArticlesLink: UrlOption;
  myLoginLink: UrlOption;
  myTopicsLink: UrlOption;
};

type AuthStateLinks = {
  minKonto: string;
  mitLogin: string;
  gemteArtikler: string;
  mineEmner: string;
}

export class AuthStateHandler {

  private links: AuthStateLinks;

  private loginBtnElems: NodeList;
  private logoutBtnElems: NodeList;
  private buyBtnElems: NodeList;
  private userNameElems: NodeList;
  private userAvatarElems: NodeList;
  private userNameTitleElems: NodeList;
  private mitLoginLinkElems: NodeList;
  private gemteArtiklerLinkElems: NodeList;
  private mineEmnerLinkElems: NodeList;

  constructor() {
  }

  init(loginUserActionHandler: () => {}, logoutUserActionHandler: () => {}) {
    try {
      this.links = window.authStateHandlerLinks

      this.loginBtnElems = document.querySelectorAll('[data-js-sel=jp-btn-login]');
      this.logoutBtnElems = document.querySelectorAll('[data-js-sel=jp-btn-logout]');
      this.buyBtnElems = document.querySelectorAll('[data-js-sel=jp-btn-buy]');
      this.userNameElems = document.querySelectorAll('[data-js-sel=jp-user-menu-name]');
      this.userAvatarElems = document.querySelectorAll('[data-js-sel=jp-user-menu-avatar]');
      this.userNameTitleElems = document.querySelectorAll('[data-js-sel=jp-user-menu-name-title]');
      this.mitLoginLinkElems = document.querySelectorAll('[data-js-sel=jp-mit-login]');
      this.gemteArtiklerLinkElems = document.querySelectorAll('[data-js-sel=jp-gemte-artikler]');
      this.mineEmnerLinkElems = document.querySelectorAll('[data-js-sel=jp-mine-emner]');

      window.addEventListener("click",
        (e) => {
          const target = (e.target as HTMLElement);
          const isLoginBtn = target.getAttribute("data-js-sel") && target.getAttribute("data-js-sel")!.indexOf('jp-btn-login') !== -1 ||
            target.closest('[data-js-sel*=jp-btn-login]');
          const isLogoutBtn = target.getAttribute("data-js-sel") && target.getAttribute("data-js-sel")!.indexOf('jp-btn-logout') !== -1 ||
            target.closest('[data-js-sel*=jp-btn-logout]');

          if (isLoginBtn) {
            loginUserActionHandler();
          } else if (isLogoutBtn) {
            logoutUserActionHandler();
            this.exponeaAnonymizeUser()
          }
        })
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * reflects auth state
   * For use in host applications.
   * @param state auth state
   */
  reflect(state: AuthState) {
    try {
      const render: AuthStateRendering = {
        showLoginButton: !state.isLoggedIn,
        showLogoutButton: state.isLoggedIn,
        showBuyButton: !(state.isLoggedIn && state.subscription.isActive),

        savedArticlesLink: state.isLoggedIn
          ? this.links.gemteArtikler
          : null,

        myLoginLink: state.isLoggedIn
          ? this.links.mitLogin
          : null,

        myTopicsLink: state.isLoggedIn ? this.links.mineEmner : null
      };

      this.setLink(this.mineEmnerLinkElems, render.myTopicsLink);
      this.setLink(this.gemteArtiklerLinkElems, render.savedArticlesLink);
      this.setLink(this.mitLoginLinkElems, render.myLoginLink);
      this.toggleVisibility(this.loginBtnElems, render.showLoginButton);
      this.toggleVisibility(this.logoutBtnElems, render.showLogoutButton);
      this.toggleVisibility(this.buyBtnElems, render.showBuyButton);
      this.setUserNameArea(state);

    } catch (e) {
      console.log('An error occured during setting auth state in header', e);
    }
  }

  private setLink(link: NodeList, url: UrlOption): void {
    if (url) {
      this.enableLink(link, url);
    } else {
      this.disableLink(link);
    }
  }

  private exponeaAnonymizeUser() {
      if (window.location.search.indexOf("loggedIn") > -1) {
          history.replaceState && history.replaceState(
              null, '', location.pathname + location.search.replace(/[\?&]loggedIn=[^&]+/, '').replace(/^&/, '?')
          )
      }
      window.exponea.anonymize()
  }

  private setUserNameArea(state: AuthState) {


    this.setText(this.userNameElems, state.userName || state.userEmail || null);

    if (state.isLoggedIn) {

      this.setText(this.userNameTitleElems, 'Du er logget ind som:')

      this.toggleVisibility(this.userNameElems, true);
      this.removeStringFromAttribute(this.userNameTitleElems, 'data-js-sel', 'jp-btn-login');
      this.removeStringFromAttribute(this.userAvatarElems, 'data-js-sel', 'jp-btn-login');
      this.removeStringFromAttribute(this.userNameElems, 'data-js-sel', 'jp-btn-login');

      this.enableLink(this.userNameTitleElems, this.links.mitLogin)
      this.enableLink(this.userAvatarElems, this.links.mitLogin)
      this.enableLink(this.userNameElems, this.links.mitLogin)

    } else {
      this.setText(this.userNameTitleElems, 'Du er ikke logget ind.')
      this.setText(this.userNameElems, null);

      this.toggleVisibility(this.userNameElems, false);
      this.deactivateLink(this.userNameTitleElems);
      this.deactivateLink(this.userAvatarElems);
      this.deactivateLink(this.userNameElems);

      this.addStringToAttribute(this.userNameTitleElems, 'data-js-sel', 'jp-btn-login');
      this.addStringToAttribute(this.userAvatarElems, 'data-js-sel', 'jp-btn-login');
      this.addStringToAttribute(this.userNameElems, 'data-js-sel', 'jp-btn-login');
    }
  }

  private toggleVisibility(nodeList: NodeList, show: boolean) {
    nodeList.forEach((el: Node) => {
      if (show) {
        (el as HTMLElement).classList.remove('m--hidden');
      } else {
        (el as HTMLElement).classList.add('m--hidden');
      }
    })
  }

  private setText(nodeList: NodeList, text: string | null) {
    nodeList.forEach((el: Node) => {
      el.textContent = text;
    })
  }

  private addStringToAttribute(nodeList: NodeList, attrName: string, value: string) {
    nodeList.forEach((el: Node) => {
      const currentAttrValue = (el as HTMLElement).getAttribute(attrName);
      if (currentAttrValue) {
        (el as HTMLElement).setAttribute(attrName, currentAttrValue + ' ' + value);
      } else {
        (el as HTMLElement).setAttribute(attrName, value);
      }
    })
  }

  private removeStringFromAttribute(nodeList: NodeList, attrName: string, value: string) {
    nodeList.forEach((el: Node) => {
      const currentAttrValue = (el as HTMLElement).getAttribute(attrName);
      if (currentAttrValue) {
        (el as HTMLElement).setAttribute(attrName, currentAttrValue.replace(value, ""));
      }
    })
  }

  private enableLink(nodeList: NodeList, url: string) {
    nodeList.forEach((el: Node) => {
      (el as HTMLElement).classList.remove('m--link-disabled');
      (el as HTMLElement).setAttribute('href', url);
    })
  }

  private disableLink(nodeList: NodeList) {
    nodeList.forEach((el: Node) => {
      (el as HTMLElement).classList.add('m--link-disabled');
      (el as HTMLElement).removeAttribute('href');
    })
  }

  private deactivateLink(nodeList: NodeList) {
    nodeList.forEach((el: Node) => {
      (el as HTMLElement).removeAttribute('href');
    })
  }
}

export let authStateHandler = new AuthStateHandler();
