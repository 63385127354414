import { Configuration, HeaderHostApp } from "./header.model";

export abstract class Header {
    config: Configuration = {};

    isSticky = false;
    megaMenuScrolledElem: HTMLElement;
    stickyTreshold: number = 0;
    pauseScrollHandler = false;

    private megaMenuElem: HTMLElement;
    private toggleMegaMenuElem: HTMLElement;
    private toggleMegaMenuElemLabel: Element;

    private lockPageYOffset: number = 0;
    public headerElem: HTMLElement;

    /**
 * @param config  Configuration.
 */
    init(config: Configuration) {
        this.config = config;

        this.headerElem = document.getElementById('site-header')!;
        this.megaMenuElem = document.getElementById('jp-mega-menu')!;
        this.megaMenuScrolledElem = document.getElementById('jp-mega-menu-scrolled')!;
        this.toggleMegaMenuElem = document.getElementById('jp-header-btn-toggle-mega-menu')!;
        this.toggleMegaMenuElemLabel = this.toggleMegaMenuElem.getElementsByClassName('jp-btn__text')[0]!;
        this.toggleMegaMenuElem = document.getElementById('jp-header-btn-toggle-mega-menu')!;

        this.setupMenu();
    }

    abstract setHeaderSticky(): void;

    private setupMenu() {
        this.toggleMegaMenuElem.addEventListener("click", () => {
            this.toggleMegaMenu();
        })
    }

    private isMegaMenuVisible() {
        return this.headerElem.classList.contains('m--show-menu');
    }
    private toggleMegaMenu() {
        if (!this.isMegaMenuVisible()) {
            this.showMenu();
        } else {
            this.hideMenu();
        }
    }

    private lockScroll(lock: boolean) {
        if (lock) {
            this.pauseScrollHandler = true;
            this.lockPageYOffset = scrollY;
            document.body.style.position = 'fixed';
        } else {
            document.body.style.position = '';
            this.pauseScrollHandler = false;
            window.scrollTo(0, this.lockPageYOffset || 0);
        }
    }

    getStickyTreshold() {
        const box = this.headerElem.getBoundingClientRect();
        return box.bottom + scrollY - 64;
    }

    hideMenu() {
        this.toggleMegaMenuElem.dataset.trackLabel = "hide-menu"
        this.toggleMegaMenuElemLabel.textContent = "Menu"
        this.lockScroll(false);
        this.headerElem.classList.remove('m--show-menu');
        document.body.classList.remove('m--showing-megamenu');
        document.documentElement.style.overflowY = 'auto';
    }

    showMenu() {
        this.toggleMegaMenuElemLabel.textContent = "Luk";
        this.toggleMegaMenuElem.dataset.trackLabel = "show-menu"
        this.headerElem.classList.add('m--show-menu');
        document.body.classList.add('m--showing-megamenu');
        this.setDocumentScrollPlaceholder();
        this.lockScroll(true);
        try {
            this.megaMenuElem.scrollTo(0, 0);
        } catch (e) {
            console.log(e);
        }
    }

    private setDocumentScrollPlaceholder() {
        if (this.megaMenuElem.offsetHeight <= this.megaMenuScrolledElem.offsetHeight) {
            document.documentElement.style.overflowY = 'hidden';
        } else {
            document.documentElement.style.overflowY = 'scroll';
        }
    }
}





