import { Header } from "./base";
import { Configuration } from "./header.model";
import { Helpers } from "./helpers";

export class FeatureHeaderFactory extends Header {

    private topInfo: Element
    private scrollDistance: number

    init(config: Configuration = { updateStickyTresholdOnScroll: false, permanentlyStickyHeader: false }) {
        super.init(config)
        this.topInfo = document.querySelector('.c-feature-top-info__content')!;
        this.scrollDistance = window.pageYOffset + this.topInfo.getBoundingClientRect().top

        this.setHeaderSticky();
    }

    setHeaderSticky() {

        window.addEventListener("resize", () => {
            this.scrollDistance = window.pageYOffset + this.topInfo.getBoundingClientRect().top
        })

        window.addEventListener("scroll", () => {
            if (window.pageYOffset >= this.scrollDistance || this.headerElem.classList.contains("m--show-menu")) {
                document.body.classList.add("m--showing-sticky-header")
                this.headerElem.classList.remove("m--feature-top");
            } else {
                document.body.classList.remove("m--showing-sticky-header")
                this.headerElem.classList.add("m--feature-top");
            }
        })
    }

    hideMenu() {
        super.hideMenu()

        if (window.pageYOffset < this.scrollDistance) {
            this.headerElem.classList.add("m--feature-top");
        }
    }

    showMenu() {
        super.showMenu()
        this.headerElem.classList.remove("m--feature-top");
    }
}
