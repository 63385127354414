import { FeatureHeaderFactory } from "./feature";
import { HeaderType } from "./header.model";
import { NormalHeaderFactory } from "./normal";

export abstract class HeaderFactory {
    static get(key: HeaderType) {
        const normalHeaderFactory = new NormalHeaderFactory();
        const featureHeaderFactory = new FeatureHeaderFactory();

        switch (key) {
            case HeaderType.NORMAL:
                return normalHeaderFactory;
                break;

            case HeaderType.FEATURE:
                return featureHeaderFactory;
                break;

            default:
                return normalHeaderFactory;
                break;
        }
    }
}
